import { clearGAEcommerceObj, sendEventToGA } from '@/managers/Analytics';
import { prepareEpisodeVideoType } from '@/managers/Analytics/videoEvents';
import {
  BodyContentModulesPropertiesNavigationBanner,
  BodyContentModulesVideoContentCards,
  BodyContentModulesVideoPlaylist,
  PbsKidsVideo,
  PbsKidsVideoPlaylist,
  PbsKidsVideosByPropertyCollection,
  PbsKidsVideosWebPage,
  PropertiesNavBannerCard,
  VideoContentCard,
} from '@/types/pbskids-graph';

export type TrackableListModulesType =
BodyContentModulesPropertiesNavigationBanner |
BodyContentModulesVideoContentCards |
BodyContentModulesVideoPlaylist |
PbsKidsVideoPlaylist |
PbsKidsVideosByPropertyCollection;

type TrackableListModuleItemType = PbsKidsVideo |
PbsKidsVideosByPropertyCollection |
PbsKidsVideoPlaylist |
PbsKidsVideosWebPage |
PropertiesNavBannerCard |
VideoContentCard;

const checkNavPropBgImage = (moduleElem: Element) => {
  return moduleElem.hasAttribute('data-ga-has-thumb-bg');
};

const getGridModuleType = (eventData: TrackableListModulesType): string => {
  let type; // carousel, grid, navigation, or row.
  if (eventData.__typename === 'BodyContentModulesPropertiesNavigationBanner') {
    type = 'navigation';
  }

  if (eventData.__typename === 'BodyContentModulesVideoPlaylist' || eventData.__typename === 'BodyContentModulesVideoContentCards') {
    type = eventData.layout;
  }

  return type || 'grid';
};

const getOrderFromIndex = (ind: number): string => {
  // Order starts at 01.
  return (ind + 1).toString().padStart(2, '0');
};

const getItemTypeLabel = (itemTypename: string): string => {
  // could be a page, playlist, show, or video.
  // game and podcast to come later.
  const targetMap: { [key: string]: string } = {
    PbsKidsMultiPlatformSeries: 'show',
    PbsKidsVideosWebPage: 'page',
    PbsKidsVideo: 'video',
    PbsKidsVideoPlaylist: 'playlist',
    PbsKidsVideosByPropertyCollection: 'playlist',
  };

  return itemTypename ? targetMap[itemTypename] : '';
};

const getItemShowLabel = (item: TrackableListModuleItemType): string => {
  if (!item) return '';

  if (item.__typename === 'PbsKidsVideosWebPage') {
    return item.property?.[0]?.title || '';
  }

  if (
    item.__typename === 'PbsKidsVideosByPropertyCollection' ||
    item.__typename === 'PbsKidsVideo' ||
    item.__typename === 'PbsKidsVideoPlaylist'
  ) {
    // Use PBSKIDS when there is more than one show.
    return item.properties && item.properties.length > 1 ? 'PBSKIDS' : item.properties?.[0]?.title || '';
  }
  return '';
};

const getFormattedListItem = (
  item: TrackableListModuleItemType,
  index: number,
  moduleName: string,
  moduleOrder: string,
  moduleType: string,
  thumbStyle: string,
): Record<string, string|number> => {
  const result = <Record<string, string|number>>{
    index: getOrderFromIndex(index),
    item_id: item?.id || '',
    item_list_name: moduleName,
    item_module_name: moduleName,
    item_module_position: moduleOrder,
    item_module_type: moduleType,
    item_thumbnail_id: thumbStyle,
  };

  if (item?.__typename === 'PbsKidsVideo') {
    result.item_guid = item.guid || '';
    result.item_media_type = getItemTypeLabel(item.__typename);
    result.item_name = item.title || '';
    result.item_show_name = getItemShowLabel(item);
    result.item_video_tp_media = item?.mediaManagerAsset?.legacy_tp_media_id || '';
    result.item_video_type = prepareEpisodeVideoType(item.videoType || '', 'full_length');
  }

  if (item?.__typename === 'PbsKidsVideoPlaylist') {
    result.item_media_type = getItemTypeLabel(item.__typename);
    result.item_name = item.title || '';
    result.item_show_name = getItemShowLabel(item);
  }

  if (item?.__typename === 'PropertiesNavBannerCard') {
    const title = item.property?.[0]?.title || '';
    result.item_media_type = 'show';
    result.item_show_name = title;
    result.item_name = title;
  }

  if (item?.__typename === 'VideoContentCard') {
    const itemTarget = item.target?.[0];

    result.item_id = itemTarget?.id || '';
    result.item_name = itemTarget?.title || '';
    result.item_media_type = getItemTypeLabel(itemTarget?.__typename || '');
    result.item_show_name = getItemShowLabel(itemTarget as TrackableListModuleItemType);
  }

  return result;
};

const getFormattedListItems = (
  moduleName: string,
  moduleOrder: string,
  moduleType: string,
  thumbStyle: string = '',
  items: Array<TrackableListModuleItemType>,
  indexOverride: number = -1,
): Array<object> => {
  return items.map((item, index) => {
    const itemIndex = indexOverride > -1 ? indexOverride : index;
    return getFormattedListItem(item, itemIndex, moduleName, moduleOrder, moduleType, thumbStyle);
  });
};

const getGAModuleParams = (
  eventName: string,
  moduleData: TrackableListModulesType,
  index: number,
  station: string,
  hasBgThumb: boolean,
  singleItemIndex: number = -1,
): Record<string, unknown> => {
  const moduleOrder = getOrderFromIndex(index);
  let items: Array<TrackableListModuleItemType> = [];
  let moduleName;
  let thumbStyle;

  switch (moduleData.__typename) {
    case 'BodyContentModulesVideoPlaylist':
      items = moduleData.collection?.[0]?.entries as Array<PbsKidsVideo>;
      moduleName = moduleData.anchor || '';
      thumbStyle = moduleData.cardStyle || '';
      break;
    case 'BodyContentModulesVideoContentCards':
      items = moduleData.cards as Array<VideoContentCard>;
      moduleName = moduleData.anchor || '';
      thumbStyle = moduleData.cardStyle || '';
      break;
    case 'BodyContentModulesPropertiesNavigationBanner':
      items = moduleData.cards as Array<PropertiesNavBannerCard>;
      moduleName = moduleData.anchor;
      thumbStyle = hasBgThumb ? 'background' : 'no-background';
      break;
    case 'PbsKidsVideoPlaylist':
    case 'PbsKidsVideosByPropertyCollection':
      items = moduleData.entries as Array<PbsKidsVideo>;
      moduleName = moduleData.title;
      thumbStyle = 'mezzanine';
      break;
  }

  if (!moduleName) {
    moduleName = `${moduleData.id}_${moduleData.__typename}`;
  }

  if (singleItemIndex > -1) {
    // Only tracking a single list item.
    const singleItem = items[singleItemIndex];
    items = [ singleItem ];
  }

  const moduleType = getGridModuleType(moduleData);

  const eventParams = {
    'event': eventName,
    'module_name': moduleName,
    'module_type': moduleType,
    'module_position': moduleOrder,
    'station_localization': station,
    'ecommerce': {
      'item_list_id': moduleName,
      'items': getFormattedListItems(moduleName, moduleOrder, moduleType, thumbStyle, items, singleItemIndex) || [],
    },
  };

  return eventParams;
};

const matchElemToPageModuleData = (elem: Element, modules: TrackableListModulesType[]) => {
  return modules?.find((module) => (module?.id ?? -1) === elem.getAttribute('data-ga-observable-module'));
};

const sendModuleClickEvent = (
  itemIndex: number,
  module: TrackableListModulesType,
  moduleIndex: number,
  station: string,
  hasBgThumb: boolean,
) => {
  clearGAEcommerceObj();
  const gaEventParams = getGAModuleParams('select_item', module, moduleIndex, station, hasBgThumb, itemIndex);
  sendEventToGA(gaEventParams);
};

const sendModuleViewEvent = (
  module: TrackableListModulesType,
  moduleIndex: number,
  station: string,
  hasBgThumb: boolean,
) => {
  clearGAEcommerceObj();
  const gaEventParams = getGAModuleParams('view_item_list', module, moduleIndex, station, hasBgThumb);
  sendEventToGA(gaEventParams);
};

const trackModuleItemClick = (elem: Element, moduleItem: TrackableListModulesType, station: string = ''): void => {
  const listItem = elem.closest('li');

  if (!listItem) return;

  const listEl = listItem.closest('ul[data-ga-observable-module]');

  if (!listEl) return;

  const moduleIndex = Array.from(document.querySelectorAll('ul[data-ga-observable-module]')).indexOf(listEl);

  const listElChildren = listEl.querySelectorAll('li');
  const clickedItemIndex = Array.from(listElChildren || [])?.indexOf(listItem);
  const hasThumbBg = checkNavPropBgImage(listEl);

  if (clickedItemIndex > -1 && moduleItem) {
    sendModuleClickEvent(clickedItemIndex, moduleItem, moduleIndex, station, hasThumbBg);
  }
};

const trackModuleView = (elem: Element, moduleData: TrackableListModulesType, station: string): void => {
  if (!moduleData) return;

  const hasThumbBg = checkNavPropBgImage(elem);

  const trackedModules = Array.from(document.querySelectorAll('ul[data-ga-observable-module]'));
  const moduleIndex = trackedModules.indexOf(elem);

  if (moduleIndex > -1) {
    sendModuleViewEvent(moduleData, moduleIndex, station, hasThumbBg);
  }
};

const trackModuleViewOnLazyLoad = (
  elem: Element, moduleData: TrackableListModulesType, station: string, delay: number = 500,
): void => {
  window.setTimeout(() => {
    const trackedModule = elem?.querySelector('[data-ga-observable-module]');

    if (trackedModule) {
      trackModuleView(trackedModule, moduleData, station);
    }
  }, delay);
};

const handleTrackedModuleItemClick = (moduleData: TrackableListModulesType, station: string, ev: Event) => {
  const elem = ev.target as Element;
  trackModuleItemClick(elem, moduleData, station);
};

export {
  handleTrackedModuleItemClick,
  matchElemToPageModuleData,
  trackModuleView,
  trackModuleViewOnLazyLoad,
};
