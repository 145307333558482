import { useContext, useEffect, useRef } from 'react';
import StationLocalizationContext from '@/contexts/StationLocalizationContext';
import {
  handleTrackedModuleItemClick,
  matchElemToPageModuleData,
  TrackableListModulesType,
  trackModuleView,
} from '@/managers/Analytics/pageModuleEvents';
import { BodyContentModuleType } from '@/components/modules/BodyContentModule';
import { PbsKidsCatalogMediaCollection } from '@/types/pbskids-graph';

export function useListModuleTracker(modulesData: BodyContentModuleType[]|PbsKidsCatalogMediaCollection[]) {
  const { station, stationError } = useContext(StationLocalizationContext);
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (
      !firstRenderRef.current ||
      !modulesData?.length ||
      (!station?.callSign && !stationError)
    ) {
      return;
    }

    firstRenderRef.current = false;

    const observables = Array.from(document.querySelectorAll('[data-ga-observable-module]'));

    const handleIntersect = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const moduleData = matchElemToPageModuleData(entry.target, modulesData as TrackableListModulesType[]);
          if (moduleData) {
            trackModuleView(entry.target, moduleData, station.callSign || '');
            // Listen for clicks on module list items
            entry.target.addEventListener('click',
              handleTrackedModuleItemClick.bind(null, moduleData, station.callSign,
              ), true);
          }
          // Just record each view the once.
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect);

    observables.forEach((el) => {
      // Watch for module entring view
      observer.observe(el);
    });

    return () => {
      observer.disconnect();
    };
  }, [ modulesData, station, stationError ]);
}

